import {Router} from '@angular/router';
import {AlertController, ToastController} from '@ionic/angular';
import {AbstractGameworldComponent} from 'app/utils/abstract.gameworld.component';


export abstract class AbstractGameworldPage extends AbstractGameworldComponent {

  protected constructor(protected router: Router,
                        toastController: ToastController,
                        alertController: AlertController
  ) {
    super(toastController, alertController);
  }
  // TODO common functions for routing.
}
